import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Apollo `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/apollo-server/data/data-sources"
      }}>{`data sources`}</a>{` are a great way to interface with your data(base) in Apollo Server.`}</p>
    <p>{`The Apollo docs will teach you a lot more about using Apollo data sources, and all their configuration. `}<strong parentName="p">{`The purpose of this tutorial is to encourage you to think of ways you can use data sources to improve your workflow`}</strong>{`.`}</p>
    <p>{`I have recently started to explore the `}<a parentName="p" {...{
        "href": "https://www.givefood.org.uk/api"
      }}>{`Give Food API`}</a>{`. It's a REST API that aims to index all of the UK food banks, covering networked and independent organisations.`}</p>
    <p>{`I started working on a few projects using the REST API, but I wanted a way to interface with Give Food using GraphQL.`}</p>
    <p>{`Since Give Food don't have a native GraphQL API, I decided to explore creating an Apollo Server that masked requests to Give Food using the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/apollo-datasource-rest"
      }}><inlineCode parentName="a">{`apollo-datasource-rest`}</inlineCode></a>{` module.`}</p>
    <p>{`However, there is a 2nd GraphQL API which needs access to Give Food API. Instead of creating the `}<inlineCode parentName="p">{`GiveFoodDataSource`}</inlineCode>{` class again in that repo, I decided to package this up into an `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/apollo-datasource-givefood"
      }}>{`NPM module`}</a>{` that can be imported and used directly with Apollo Server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i apollo-datasource-givefood
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/apollo-server"
      }}>{`Apollo Server`}</a>{` accepts a `}<inlineCode parentName="p">{`dataSources`}</inlineCode>{` function that expects your data sources.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { GiveFoodDataSource } from "apollo-datasource-givefood";

const server = new ApolloServer({
  typeDefs,
  resolvers,
  dataSources: () => ({
    givefood: new GiveFoodDataSource(),
  }),
});
`}</code></pre>
    <p>{`These data sources are then accessible inside your GraphQL resolver `}<inlineCode parentName="p">{`context`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Query: {
  foodbank: async (_source, { slug }, { dataSources }) => {
    return dataSources.givefood.getBySlug(slug);
  },
}
`}</code></pre>
    <h2>{`What's involved?`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`GiveFoodDataSource`}</inlineCode>{` class extends the `}<inlineCode parentName="p">{`RESTDataSource`}</inlineCode>{` and has defined the methods for retrieving data from the Give Food REST API.`}</p>
    <p>{`Give Food exposes an API for all organisations, food bank by slug, and search params for lat/lng or address.`}</p>
    <p>{`It made sense to split all of this out into 4 methods:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`getAll()`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`getBySlug(slug)`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`getByLatLng(lat, lng)`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`getByAddress(address)`}</inlineCode></li>
    </ul>
    <p>{`For each of these methods, we can use the class methods to `}<inlineCode parentName="p">{`get`}</inlineCode>{` data from our `}<inlineCode parentName="p">{`baseURL`}</inlineCode>{`. `}<inlineCode parentName="p">{`baseURL`}</inlineCode>{` is required by `}<inlineCode parentName="p">{`RESTDataSource`}</inlineCode>{` and all requests to `}<inlineCode parentName="p">{`get`}</inlineCode>{`/`}<inlineCode parentName="p">{`post`}</inlineCode>{`, etc. are relative to this.`}</p>
    <p>{`In the end, the code ended up being:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { RESTDataSource } = require("apollo-datasource-rest");

class GiveFoodDataSource extends RESTDataSource {
  constructor() {
    super();
    this.baseURL = "https://www.givefood.org.uk/api/1/";
  }

  async getAll() {
    return this.get("foodbanks");
  }

  async getBySlug(slug) {
    return this.get(\`foodbank/\${slug}\`);
  }

  async getByLatLng(lat, lng) {
    return this.get(\`foodbanks/search\`, {
      lattlong: \`\${lat},\${lng}\`,
    });
  }

  async getByAddress(address) {
    return this.get(\`foodbanks/search\`, {
      address,
    });
  }
}
`}</code></pre>
    <p>{`You can even go further by subclassing the `}<inlineCode parentName="p">{`GiveFoodDataSource`}</inlineCode>{` if Give Food updated their API `}<inlineCode parentName="p">{`baseURL`}</inlineCode>{`, or you wanted to add a method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { GiveFoodDataSource } from "apollo-datasource-givefood";

class FoodBanks extends GiveFoodDataSource {
  constructor() {
    super();
    this.baseURL = "...";
  }

  getFoodBankBySlug(slug) {
    return this.getBySlug(slug);
  }
}
`}</code></pre>
    <p>{`I hope this is some help and inspiration to start building your own data sources. As you can see, this package isn't doing much but providing a clear and declarative way to call the Give Food API.`}</p>
    <p>{`This is now available inside my GraphQL context. 🚀`}</p>
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/notrab/apollo-datasource-givefood"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/apollo-datasource-givefood"
        }}>{`NPM`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/apollo-server/data/data-sources/"
        }}>{`Apollo Data sources`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      